<template>
  <div class="h-full">
    <List
      title="Permissions"
      resource="customerPermissions"
      :basePath="basePath"
      :fields="fields"
      :request-params="{customerId: customerId, nonDeleted: true}"
      ref="list"
      track-by="id"
      on-row-click="none"
      infinity-scroll
      :initial-filter="filter"
      :search="true"
      search-placeholder="Search for a permission by name or description"
      :filter-mapper="mapFilterToQuery"
    >
      <template v-slot:filters="props">
        <div class="form-row">
          <div class="form-col">
            <SelectInput
              name="appIds"
              label="Application"
              :options="apps"
            />
          </div>
        </div>
        <div class="form-row justify-end">
          <button @click.stop="props.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
          <button class="btn-primary">Filter</button>
        </div>
      </template>
    </List>
    <div id="modal-footer" class="modal-footer">
      <RolesPermissionsSyncButton :customerId="customerId" @synchronized="synchronized"/>
    </div>
  </div>
</template>

<script>
import List from '@/components/auth/list/List';
import ModalNavigation from '@/mixins/ModalNavigation';
import {get} from 'lodash-es';
import SelectInput from '@/components/form/SelectInput';
import RolesPermissionsSyncButton from "@/components/auth/list/RolesPermissionsSyncButton";
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";

export default {
  name: 'PermissionsList',
  components: {
    SelectInput,
    List,
    RolesPermissionsSyncButton,
  },
  mixins: [ModalNavigation, ModalFooterMixin],
  computed: {
    basePath: function () {
      return this.$route.path;
    },
    customerId: function () {
      return this.$route.params.customerId;
    }
  },
  data() {
    return {
      apps: [],
      filter: {
        appIds: undefined,
      },
      fields: [
        {
          name: 'appName',
          sortField: 'appName',
          title: 'Application',
        },
        {
          name: 'alias',
          sortField: 'alias',
          title: 'Permission Name',
        },
        {
          name: 'id',
          sortField: 'id',
          title: 'Permission ID',
        },
        {
          name: 'description',
          title: 'Permission Description',
        },
        {
          name: 'isSupportedByCustomRoles',
          sortField: 'isSupportedByCustomRoles',
          title: 'Support for custom roles',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
    };
  },
  created() {
    this.$authDataProvider.getList('customerApps', {customerId: this.customerId})
      .then(apps => {
        this.apps = apps.filter(app => !app.isCustomerUserAgnostic)
          .map(({name: value, id: key}) => ({
            value,
            key
          }));
      })
      .catch((err) => console.log(err));
  },
  methods: {
    mapFilterToQuery(values) {
      return {
        appId: get(values, 'appIds'),
      };
    },

    synchronized() {
      this.$refs.list.reload();
    },
  }
}
</script>

<style scoped>
  #modal-footer {
    justify-content: flex-end;
  }
</style>
